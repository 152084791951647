import { Component } from '@angular/core';
import { OverlayContainer } from '@angular/cdk/overlay';
import { Router, NavigationEnd, NavigationCancel, ActivationStart } from '@angular/router';
import { AuthService, LoggingService } from '../../../../Packages/npm/moondesk-web/projects/moondesk-web-lib/src/public_api';
import { MatDialog } from '@angular/material/dialog';
import { AcceptLegalsComponent } from './content/legals/components/accept-legals/accept-legals.component';
import { trigger, transition, style, animate } from '@angular/animations';
import { UpdateService } from './content/services/update.service';
import { GlobalWorkerOptions } from 'pdfjs-dist';
import { environment } from 'src/environments/environment';

GlobalWorkerOptions.workerSrc = environment.pdfjsWorkerUrl;


const fadeAnimation = trigger('fadeAnimation', [
  transition(':enter', [
    style({ opacity: 0 }), animate('100ms', style({ opacity: 1 }))]
  ),
  transition(':leave',
    [style({ opacity: 1 }), animate('100ms', style({ opacity: 0 }))]
  )
]);

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    animations: [fadeAnimation],
    standalone: false
})



export class AppComponent
{
  loading: boolean = true;

  constructor(public overlayContainer: OverlayContainer,
              private router: Router, private log: LoggingService,
              private authService: AuthService, private dialog: MatDialog,
              private sw: UpdateService)
  {
    router.events.subscribe(event =>
    {
      // to show the splash screen
      if (event instanceof ActivationStart)
      {
        this.loading = true;
      }
      else if (event instanceof NavigationEnd)
      {
        this.loading = false;
        this.log.trackPageView(event.urlAfterRedirects);
      }
      else if (event instanceof NavigationCancel)
      {
        this.loading = false;
      }
    });
    authService.usersApprovalMissing.subscribe(() => this.openAcceptLegalsComponent());
    this.sw.checkForUpdates();
  }

  private async openAcceptLegalsComponent()
  {
    if (this.router.url.indexOf('/privacy') >= 0 ||
         this.router.url.indexOf('/termsandconditions') >= 0)
    {
      return '';
    }
    while (!this.loading)
    {
      await this.delay(1000);
    }
    const identity = this.authService.getCurrentIdentity();
    const dialogRef = this.dialog.open(AcceptLegalsComponent,
      {
        width: '380px',
        height: 'auto',
        disableClose: true,
        data: { identity: identity }
      }
    );

    dialogRef.afterClosed().subscribe(async() =>
    {
      try
      {
        await this.authService.acceptLegals();
      }
      catch (err)
      {
        console.log('Error accepting legals');
        console.log(err);
      }
    });
  }

  private delay(ms: number)
  {
    return new Promise(resolve => setTimeout(resolve, ms));
  }
}
