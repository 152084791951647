import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CommonModule } from '@angular/common';
import { Component, CUSTOM_ELEMENTS_SCHEMA, Inject } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { TranslateModule } from '@ngx-translate/core';
import { Identity } from '../../../../../../../../Packages/npm/moondesk-web/projects/moondesk-web-lib/src/public_api';

@Component({
  selector: 'app-accept-legals',
  templateUrl: './accept-legals.component.html',
  styleUrls: ['./accept-legals.component.scss'],
  standalone: true,
  imports: [CommonModule, TranslateModule, MatCheckboxModule, FormsModule],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AcceptLegalsComponent
{
  acceptedPrivacy: boolean = false;
  acceptedTerms: boolean = false;
  identity: Identity;

  constructor(public dialogRef: MatDialogRef<AcceptLegalsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any)
  {
    this.identity = data.identity;
  }

  goToPrivacy()
  {
    const url = `${window.location.origin}/privacy`;
    window.open(url);
  }

  goToTermsAndConditions()
  {
    const url = `${window.location.origin}/termsandconditions`;
    window.open(url);
  }

  allChecked(): boolean
  {
    return this.acceptedPrivacy && (this.acceptedTerms || !this.identity.user);
  }

  close()
  {
    if (this.allChecked())
    {
      this.dialogRef.close();
    }
  }
}
