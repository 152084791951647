<div class="accept-legals">

    <div class="header">
        <img [src]="'../assets/logo.svg'" alt="Moondesk logo">
    </div>

    <div class="body">

        <div class="label" translate>lid.legals.accept-legals.pleaseAccept</div>

        <div class="checkboxes">

            <!-- TERMS AND CONDITIONS-->
            <mat-checkbox class="checkbox" e2eLocator e2e="acceptTermsCheckbox" [(ngModel)]="acceptedTerms" *ngIf="identity.user">
                <span class="text" translate>lid.legals.accept-legals.iAccept</span>
                <a class="link" (click)="goToTermsAndConditions()" translate>lid.legals.accept-legals.termsAndCondition</a>
            </mat-checkbox>

             <!-- PRIVACY POLICY -->
             <mat-checkbox class="checkbox" color="primary" e2eLocator e2e="acceptPrivacyCheckbox" [(ngModel)]="acceptedPrivacy">
                <span class="text" translate>lid.legals.accept-legals.iAcceptFem</span>
                <span class="link" e2eLocator e2e="acceptTermsCheckbox" (click)="goToPrivacy()" translate>lid.legals.accept-legals.privacyPolicy</span>
            </mat-checkbox>

        </div>

        <div class="description" translate>lid.legals.accept-legals.description</div>

    </div>

    <div class="actions">
        <button class="moon-btn def-btn full-primary-btn r-4" e2eLocator e2e="okButton" [disabled]="!allChecked()" (click)="close()" translate>lid.legals.accept-legals.ok</button>
    </div>

</div>
