import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { FindState, NgxExtendedPdfViewerService, TextLayerRenderedEvent } from 'ngx-extended-pdf-viewer';
import { debounceTime, Subject, takeUntil } from 'rxjs';
import { TextDifference, DiffType } from '../../../../../../../../../../Packages/npm/moondesk-web/projects/moondesk-web-lib/src/public_api';
export interface ScrollPosition
{
  scrollTop: number;
  scrollLeft: number;
}
export interface TextDifferencesData
{
  textDifferences: TextDifference[];
  highlightInSelection: boolean;
  keepOldHighlights: boolean;
}
interface HighlightData
{
  rect: { left: number; top: number; width: number; height: number };
  textDifference: TextDifference;
  zoomFactor: number;
  color: string;
}

@Component({
    selector: 'app-moon-pdf-viewer',
    templateUrl: './moon-pdf-viewer.component.html',
    styleUrls: ['./moon-pdf-viewer.component.scss'],
    standalone: false
})
export class MoonPdfViewerComponent implements AfterViewInit, OnDestroy
{
  @Input() src!: string;
  @Input() page: number = 1;
  @Input() showAllPages: boolean;
  @Input() panOnClickDrag: boolean = true;
  @Input() set searchText(text: string)
  {
    this.search(text);
  }

  @Input() pdfJsViewer: boolean = false;

  @Input() set highlightDiffs(data: TextDifferencesData)
  {
    if (!data || !data.textDifferences)
    {
      return;
    }

    if (data.textDifferences.length === 0)
    {
      this.removeAllHighlights();
      return;
    }

    const newHighlights = this.mapDiffsToHighlightsRects(data.textDifferences, data.highlightInSelection);
    if (data.keepOldHighlights)
    {
      this.addHighlightsWithoutDuplicates(newHighlights);
    }
    else
    {
      this.highlights = newHighlights;
    }
    this.highlightRects(newHighlights);
  }

  @Input() set focusEqualDiff(equalDiffIndex: number)
  {
    if (equalDiffIndex >= 0)
    {
      this.scrollToEqualDiff(equalDiffIndex);
    }
  }

  @Input() set zoomLevel(zoom: number | string)
  {
    this._zoom = zoom;
  }
  @Output() zoomLevelChange: EventEmitter<number | string> = new EventEmitter<number | string>();

  @Input() set scrollPosition(scroll: ScrollPosition)
  {
    if (this.viewerContainer)
    {
      this.viewerContainer.scrollTo(scroll.scrollLeft, scroll.scrollTop);
    }
  }
  @Output() scrollChange: EventEmitter<ScrollPosition> = new EventEmitter<ScrollPosition>();


  @Output() pageRendered: EventEmitter<HTMLCanvasElement> = new EventEmitter<HTMLCanvasElement>();
  @Output() totalPages: EventEmitter<number> = new EventEmitter<number>();
  @Output() allTextReaded: EventEmitter<string> = new EventEmitter<string>();
  @Output() searchFound: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() textSelected: EventEmitter<string> = new EventEmitter<string>();
  @Output() equalDiffClicked: EventEmitter<number> = new EventEmitter<number>();

  enableParagraphSelection: boolean = false;

  textLayers: Map<number, HTMLElement> = new Map<number, HTMLElement>();
  canvasWrapper: HTMLElement | null = null;
  highlights: HighlightData[] = [];

  private destroy$ = new Subject<void>();
  private clickSubject = new Subject<MouseEvent>();
  private annotationLayerRenderedSubject = new Subject<void>();

  // Obsolete
  zoom: number = 1;

  _zoom: number | string = 'auto';
  zoomFactor: number;
  isDragging: boolean;
  busy = true;

  private viewerContainer: HTMLElement | null = null;

  constructor(private ngxExtendedPdfViewerService: NgxExtendedPdfViewerService, private el: ElementRef)
  {}

  ngAfterViewInit(): void
  {
    // Listen to click events on the pdf text layer
    this.clickSubject.pipe(debounceTime(100), takeUntil(this.destroy$)).subscribe((event: MouseEvent) =>
    {
      const target = event.target as HTMLElement;

      if (target.classList.contains('textLayer'))
      {
        return;
      }

      if (this.enableParagraphSelection)
      {
        const highlight = this.getHighlightOnPosition(event, target);
        if (highlight)
        {
          this.findEqualDiffIndexAndEmit(highlight);
        }
        else
        {
          this.highlightParagraph(target);
        }
      }
    });

    this.annotationLayerRenderedSubject.pipe(debounceTime(200), takeUntil(this.destroy$)).subscribe(() =>
    {
      this.highlightRects(this.highlights);
    });
  }

  ngOnDestroy()
  {
    if (this.viewerContainer)
    {
      this.viewerContainer.removeEventListener('scroll', this.onScroll);
    }
    this.destroy$.next();
    this.destroy$.complete();
  }

  onPdfLoaded()
  {
    this.viewerContainer = document.getElementById('viewerContainer');
    if (this.viewerContainer)
    {
      // Just in case
      this.viewerContainer.removeEventListener('scroll', this.onScroll);
      this.viewerContainer.addEventListener('scroll', this.onScroll);
    }
    this.removeAllHighlights();
    if (this.enableParagraphSelection)
    {
      this.toggleParagraphSelection();
    }
  }

  onZoomFactorChange(event: number)
  {
    this.zoomFactor = event;
  }

  onAnnotationLayerRendered()
  {
    this.canvasWrapper = document.querySelector('.canvasWrapper') as HTMLElement;
    this.annotationLayerRenderedSubject.next();
  }

  async onTextLayerRendered(event: TextLayerRenderedEvent)
  {
    this.textLayers.set(event.pageNumber, event.layer.div);
    const fullText = await this.getAllText();
    this.allTextReaded.emit(fullText);
  }

  updateFindState(event: FindState)
  {
    if (event === FindState.NOT_FOUND)
    {
      this.searchFound.emit(false);
    }
    else if (event === FindState.FOUND)
    {
      this.searchFound.emit(true);
    }
  }

  toggleParagraphSelection()
  {
    this.enableParagraphSelection = !this.enableParagraphSelection;
    if (this.enableParagraphSelection)
    {
      this.textLayers.forEach((layer) =>
      {
        layer.addEventListener('click', (event: MouseEvent) => { this.clickSubject.next(event); });
        layer.querySelectorAll('span').forEach((span) =>
        {
          span.classList.add('pointer-mode');
        });
      });
    }
    else
    {
      this.clearHighlights();
      this.textLayers.forEach((layer) =>
      {
        layer.removeAllListeners('click');
        layer.querySelectorAll('span').forEach((span) =>
        {
          span.classList.remove('pointer-mode');
        });
      });
    }
  }

  private getHighlightOnPosition(event: MouseEvent, target: HTMLElement): HighlightData | undefined
  {
    const wrapperRect = this.canvasWrapper.getBoundingClientRect();
    const rect = target.getBoundingClientRect();
    const clickX = rect.left - wrapperRect.left + (event.clientX - rect.left);
    const clickY = rect.top - wrapperRect.top + (event.clientY - rect.top);

    const highlightInsideClickedTarget = this.highlights.find(h =>
      h.rect.left * (this.zoomFactor / h.zoomFactor) <= clickX &&
      (h.rect.left + h.rect.width) * (this.zoomFactor / h.zoomFactor) >= clickX &&
      h.rect.top * (this.zoomFactor / h.zoomFactor) <= clickY &&
      (h.rect.top + h.rect.height) * (this.zoomFactor / h.zoomFactor) >= clickY);
    return highlightInsideClickedTarget;
  }

  private findEqualDiffIndexAndEmit(equalDiff: HighlightData)
  {
    const equalDiffs = this.highlights.filter(h => h.textDifference.type === DiffType.Equal);
    const index = equalDiffs.indexOf(equalDiff);
    this.equalDiffClicked.emit(index);
  }

  private scrollToEqualDiff(index: number)
  {
    if (!this.highlights || this.highlights.length === 0)
    {
      return;
    }
    const equalDiffs = this.highlights.filter(h => h.textDifference.type === DiffType.Equal);
    if (index >= equalDiffs.length)
    {
      return;
    }
    const equalDiff = equalDiffs[index];
    const x = equalDiff.rect.left * (this.zoomFactor / equalDiff.zoomFactor);
    const y = equalDiff.rect.top * (this.zoomFactor / equalDiff.zoomFactor);

    if (this.viewerContainer)
    {
      const containerWidth = this.viewerContainer.clientWidth;
      const containerHeight = this.viewerContainer.clientHeight;
      const scrollX = x - containerWidth / 2;
      const scrollY = y - containerHeight / 2;
      this.viewerContainer.scrollTo(scrollX, scrollY);
    }
  }

  private mapDiffsToHighlightsRects(diffs: TextDifference[], highlightInSelection: boolean): HighlightData[]
  {
    if (!diffs || diffs.length === 0)
    {
      return [];
    }

    const spansToAnalyze: HTMLElement[] = this.getTextSpans(highlightInSelection);

    let generalIndex = 0;
    const highlightData: HighlightData[] = [];

    const wrapperRect = this.canvasWrapper.getBoundingClientRect();

    spansToAnalyze.forEach((el: HTMLElement) =>
    {
      const text = el.textContent.normalize('NFKC');

      for (let index = 0; index < text.length; index++)
      {
        const target = text[index];

        while (
          generalIndex < diffs.length &&
          diffs[generalIndex].text !== target &&
          diffs[generalIndex].type !== DiffType.Equal
        )
        {
          generalIndex++;
        }

        const currentDifference = diffs[generalIndex];

        if (!currentDifference)
        {
          break;
        }

        let color: string;
        switch (currentDifference.type)
        {
          case DiffType.Inserted:
            color = 'rgba(0, 255, 0, 0.3)';
            break;
          case DiffType.Deleted:
            color = 'rgba(255, 0, 0, 0.3)';
            break;
          case DiffType.Equal:
            color = 'rgba(220,220,220, 0.3)';
            break;
        }

        let charIndex = index;
        let node = el.firstChild;

        // Sometimes a span can contain another span inside it,
        // this could happen when there is a text selection by the search text functionality.
        // In those cases, we need to find the correct span to highlight.
        while (node && charIndex >= 0)
        {
          if (node.nodeType === Node.TEXT_NODE)
          {
            if (charIndex < node.textContent.length)
            {
              break;
            }
            charIndex -= node.textContent.length;
          }
          node = node.nextSibling;
        }

        if (node)
        {
          const range = document.createRange();
          range.setStart(node, charIndex);
          range.setEnd(node, charIndex + 1);


          const rects = range.getClientRects();
          const rect = rects[0];
          if (rect)
          {
            const highlightRect =
            {
              top: rect.top - wrapperRect.top,
              left: rect.left - wrapperRect.left,
              width: rect.width,
              height: rect.height
            };
            highlightData.push({ rect: highlightRect, color: color, zoomFactor: this.zoomFactor, textDifference: currentDifference });
          }
        }
        generalIndex++;
      }
    });

    return highlightData;
  }

  private addHighlightsWithoutDuplicates(newHighlights: HighlightData[])
  {
    const highlightsWithoutDuplicates = [...this.highlights];

    newHighlights.forEach(newHighlight =>
    {
      const isDuplicate = this.highlights.some(oldHighlight =>
        Math.abs((oldHighlight.rect.left * (this.zoomFactor / oldHighlight.zoomFactor)) - newHighlight.rect.left) <= 2 &&
        Math.abs((oldHighlight.rect.top * (this.zoomFactor / oldHighlight.zoomFactor)) - newHighlight.rect.top) <= 2 &&
        Math.abs((oldHighlight.rect.width * (this.zoomFactor / oldHighlight.zoomFactor)) - newHighlight.rect.width) <= 2 &&
        Math.abs((oldHighlight.rect.height * (this.zoomFactor / oldHighlight.zoomFactor)) - newHighlight.rect.height) <= 2
      );

      if (!isDuplicate)
      {
        highlightsWithoutDuplicates.push(newHighlight);
      }
    });

    this.highlights = highlightsWithoutDuplicates;
  }

  private removeAllHighlights()
  {
    this.highlights = [];
    this.resetHighlights();
  }

  async resetHighlights()
  {
    if (!this._zoom)
    {
      this._zoom = 'auto';
      return;
    }
    const currentZoom = this._zoom;

    if (!isNaN(this._zoom as number))
    {
      this._zoom = this._zoom as number + 0.01;
    }
    else
    {
      this._zoom = 500;
    }
    await this.delay(50);
    this._zoom = currentZoom;
  }

  private highlightRects(highlights: HighlightData[])
  {
    if (!highlights || highlights.length === 0)
    {
      return;
    }

    const canvas = this.canvasWrapper.querySelector('canvas') as HTMLCanvasElement;
    const ctx = canvas.getContext('2d');

    highlights.forEach((highlight) =>
    {
      const rect = highlight.rect;
      const adjustedLeft = rect.left * (this.zoomFactor / highlight.zoomFactor);
      const adjustedTop = rect.top * (this.zoomFactor / highlight.zoomFactor);
      const adjustedWidth = rect.width * (this.zoomFactor / highlight.zoomFactor);
      const adjustedHeight = rect.height * (this.zoomFactor / highlight.zoomFactor);

      ctx.fillStyle = highlight.color;
      ctx.fillRect(adjustedLeft, adjustedTop, adjustedWidth, adjustedHeight);
    });
  }

  private delay(ms: number)
  {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  private onScroll = (event: Event): void =>
  {
    const target = event.target as HTMLElement;
    this.scrollChange.emit({ scrollTop: target.scrollTop, scrollLeft: target.scrollLeft });
  };

  private search(text: string)
  {
    this.ngxExtendedPdfViewerService.find(text,
    {
      highlightAll: true,
      matchCase: false,
      wholeWords: false,
    });
  }

  private async getAllText()
  {
    const text = await this.ngxExtendedPdfViewerService.getPageAsText(1);
    return text;
  }

  private highlightParagraph(target: HTMLElement)
  {
    this.clearHighlights();
    const paragraphSpans = this.getParagraphSpans(target);
    paragraphSpans.forEach((span) => span.classList.add('highlight'));

    let text = paragraphSpans.map((span) => span.textContent).join(' ');
    text = text.replace(/\s+/g, ' ').trim();
    this.textSelected.emit(text);
  }

  private clearHighlights()
  {
    const highlightedSpans = this.el.nativeElement.querySelectorAll('.highlight');
    highlightedSpans.forEach((span) => span.classList.remove('highlight'));
  }

  private getParagraphSpans(target: HTMLElement): HTMLElement[]
  {
    let spansToAnalyze: HTMLElement[] = this.getTextSpans();
    if (spansToAnalyze?.length === 0)
    {
      return [];
    }
    spansToAnalyze.splice(spansToAnalyze.indexOf(target), 1);

    const nearbySpans: Set<HTMLElement> = new Set([]);
    const queue: HTMLElement[] = [target];
    const threshold = this.getThreshold(spansToAnalyze);

    while (queue.length > 0)
    {
      const currentTarget = queue.shift();
      spansToAnalyze = spansToAnalyze.filter(span => span !== currentTarget);
      nearbySpans.add(currentTarget);
      const newNearbySpans = this.getNearbySpans(currentTarget, spansToAnalyze, threshold.thresholdTop, threshold.thresholdLeft);

      for (const span of newNearbySpans)
      {
        spansToAnalyze = spansToAnalyze.filter(s => span !== s);

        let isDuplicated: boolean = false;
        nearbySpans.forEach((nearbySpan) =>
        {
          const spanRect = span.getBoundingClientRect();
          const nearbySpanRect = nearbySpan.getBoundingClientRect();

          if (spanRect.top === nearbySpanRect.top && spanRect.left === nearbySpanRect.left)
          {
            isDuplicated = true;
            return;
          }
        });

        if (!isDuplicated)
        {
          nearbySpans.add(span);
          queue.push(span);
        }
      }
    }

    const orderedNearbySpans = Array.from(nearbySpans).sort((a, b) =>
    {
      const rectA = a.getBoundingClientRect();
      const rectB = b.getBoundingClientRect();

      const margin = 2; // Consider a small margin for top comparison

      if (Math.abs(rectA.top - rectB.top) <= margin)
      {
        return rectA.left - rectB.left;
      }
      return rectA.top - rectB.top;
    });

    return Array.from(orderedNearbySpans);
  }

  private getNearbySpans(target: HTMLElement, spans: HTMLElement[], maxDistanceY: number, maxDistanceX: number): HTMLElement[]
  {
    maxDistanceX = maxDistanceX > 0 ? maxDistanceX : maxDistanceY;
    if (!spans || spans.length === 0)
    {
      return [];
    }

    const targetRect = target.getBoundingClientRect();

    const targetText = target.textContent.trim();
    const targetTextEndWithStop = targetText.endsWith('.');

    const closestSpans = spans.filter((span) =>
    {
      const spanRect = span.getBoundingClientRect();
      const isCloseHorizontally =
        Math.abs(spanRect.left - targetRect.left) < maxDistanceX ||
        Math.abs(spanRect.left - targetRect.right) < maxDistanceX ||
        Math.abs(spanRect.right - targetRect.left) < maxDistanceX;
      const isCloseVertically = Math.abs(spanRect.top - targetRect.top) < maxDistanceY;

      const isSpanUnderCurrentSpan = spanRect.top > targetRect.top;
      const spanText = span.textContent.trim();
      const spanTextEndWithStop = spanText.endsWith('.');
      const isSpanOverCurrentSpan = spanRect.top < targetRect.top;

      const isFullStop = (targetTextEndWithStop && isSpanUnderCurrentSpan) || (spanTextEndWithStop && isSpanOverCurrentSpan);

      return !isFullStop && isCloseHorizontally && isCloseVertically;

    });
    return closestSpans;
  }

  /**
   * Analyzes the distance between spans and calculates the average for
   * those that are close (distance less than 100px and greater than 1px)
   */
  private getThreshold(spans: HTMLElement[]): { thresholdTop: number; thresholdLeft: number }
  {
    if (spans.length < 2)
    {
      return { thresholdTop: 0, thresholdLeft: 0 };
    }

    let totalTopDistance = 0;
    let totalLeftDistance = 0;
    let countT = 0;
    let countL = 0;

    for (let i = 0; i < spans.length - 1; i++)
    {
      const rect1 = spans[i].getBoundingClientRect();
      const rect2 = spans[i + 1].getBoundingClientRect();

      const topDistance = Math.abs(rect2.top - rect1.top);
      if (topDistance > 1 && topDistance < 100)
      {
        totalTopDistance += topDistance;
        countT++;
      }
      const leftDistance = Math.abs(rect2.left - rect1.left);
      // Texts inside the same paragraph should not be too far apart horizontally
      if (leftDistance > 1 && leftDistance < 40)
      {
        totalLeftDistance += leftDistance;
        countL++;
      }
    }

    const thresholdTop = totalTopDistance / countT;
    const thresholdLeft = totalLeftDistance / countL;

    return { thresholdTop, thresholdLeft };
  }

  private getTextSpans(highlightedSpans?: boolean): HTMLElement[]
  {
    let spans: HTMLElement[] = [];
    this.textLayers.forEach((layer) =>
    {
      if (!layer)
      {
        return;
      }
      spans = spans.concat(Array.from(layer.querySelectorAll('span')));
    });
    spans = spans.filter((span) => !span.classList.contains('markedContent'));

    // remove duplicates based on rect
    const uniqueSpans = new Map<string, HTMLElement>();

    spans.forEach((span) => {
      const rect = span.getBoundingClientRect();
      const key = `${Math.floor(rect.top)}-${Math.floor(rect.left)}-${Math.floor(rect.width)}-${Math.floor(rect.height)}`;
      if (!uniqueSpans.has(key)) {
      uniqueSpans.set(key, span);
      }
    });

    spans = Array.from(uniqueSpans.values());

    if (highlightedSpans)
    {
      const spansWithHighlights = spans.filter((span) => span.classList.contains('highlight'));
      spans = spansWithHighlights;
    }

    return spans;
  }

  /**
   *
   * OLD PDF VIEWER FUNCTIONS - TO BE REMOVED OR TRANSLATED TO THE NEW ONE (ngx-extended-pdf-viewer)
   *
   */

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onDrag(event: MouseEvent, pdfViewer: any)
  {
    if (!this.panOnClickDrag)
    {
      return;
    }
    if (this.isDragging)
    {
      const pdfContainer = pdfViewer.element.nativeElement.children[0] as HTMLElement;
      const x = pdfContainer.scrollLeft - event.movementX;
      const y = pdfContainer.scrollTop - event.movementY;
      pdfContainer.scrollTo(x, y);
    }
  }

  onDragStarted()
  {
    this.isDragging = true;
  }

  onDragEnded()
  {
    this.isDragging = false;
  }

  onZoomChange(event: number | string)
  {
    this._zoom = event;
    this.zoomLevelChange.emit(event);
  }

  onMouseWheel(event: WheelEvent)
  {
    event.preventDefault();
    if (event.deltaY < 0)
    {
      this.zoomIn();
    }
    else
    {
      this.zoomOut();
    }
  }

  /**
   * https://github.com/VadimDez/ng2-pdf-viewer#after-load-complete
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onLoadComplete(event: any)
  {
    this.totalPages.emit(event._pdfInfo.numPages);
  }

  zoomIn()
  {
    if (this.zoom < 8)
    {
      this.zoom += 0.25;
    }
  }

  zoomOut()
  {
    if (this.zoom >= 0.50)
    {
      this.zoom -= 0.25;
    }
  }

  /**
   * https://github.com/VadimDez/ng2-pdf-viewer#page-rendered
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onPageRendered(event: any)
  {
    if (event.pageNumber === this.page)
    {
      this.busy = false;
      this.pageRendered.emit(event.source.canvas);
    }
  }
}
