import { Component, OnDestroy, OnInit } from '@angular/core';
import
{
  TextDifference,
  DiffType
} from '../../../../../../../../../../../Packages/npm/moondesk-web/projects/moondesk-web-lib/src/public_api';
import
{
  ScrollPosition,
  TextDifferencesData
} from '../../moon-pdf-viewer/moon-pdf-viewer.component';

export interface TextCompareMessageToParent
{
  action: 'FULL_TEXT_READED' | 'READY' | 'SEARCH_NOT_FOUND' | 'SCROLL_CHANGE' | 'ZOOM_CHANGE' | 'TEXT_SELECTED' | 'EQUAL_DIFF_CLICKED';
  payload?: string | ScrollPosition | number;
  iframeIndex?: number;
}

export interface TextCompareMessageToChildren
{
  action:
    'SET_INDEX' |
    'SET_PDF_SRC' |
    'HIGHLIGHT_DIFFS' |
    'SEARCH_TEXT' |
    'HIGHLIGHT_DIFFS_IN_SELECTION' |
    'SET_SCROLL' |
    'SET_ZOOM' |
    'FOCUS_EQUAL_DIFF' |
    'REMOVE_HIGHLIGHTS';
  payload: string | TextDifference[] | ScrollPosition | number;
}

@Component({
  selector: 'app-pdf-viewer-iframe',
  templateUrl: './pdf-viewer-iframe.component.html',
  styleUrl: './pdf-viewer-iframe.component.scss',
  standalone: false
})
export class PdfViewerIframeComponent implements OnInit, OnDestroy
{
  private messageListener: (event: MessageEvent) => void;

  searchText: string = '';
  pdfSrc: string;
  // textLayers: Map<number, HTMLElement>;
  // private textLayerData: PdfTextLayerData;
  scrollPosition: ScrollPosition;
  zoomLevel: number | string;
  selectedParagraphIndex: null | number;
  diffsToHighlight: TextDifferencesData;
  equalDiffIndexToFocus: number = -1;

  iframeIndex = -1;
  ngOnInit()
  {
    this.messageListener = (event: MessageEvent) =>
    {
      if (event.origin !== window.location.origin)
      {
        return;
      }

      const data = event.data as TextCompareMessageToChildren;

      switch (data.action)
      {
        case 'SET_INDEX':
          this.iframeIndex = +data.payload;
          break;
        case 'SET_PDF_SRC':
          this.pdfSrc = data.payload as string;
          break;
        case 'HIGHLIGHT_DIFFS':
          this.highlightDifferences(data.payload as TextDifference[], false);
          break;
        case 'SEARCH_TEXT':
          this.searchText = data.payload as string;
          break;
        case 'HIGHLIGHT_DIFFS_IN_SELECTION':
          this.highlightDifferences(data.payload as TextDifference[], true);
          break;
        case 'REMOVE_HIGHLIGHTS':
          this.removeDiffHighlights();
          break;
        case 'SET_SCROLL':
          this.scrollPosition = data.payload as ScrollPosition;
          break;
        case 'SET_ZOOM':
          this.zoomLevel = data.payload as number | string;
          break;
        case 'FOCUS_EQUAL_DIFF':
          this.equalDiffIndexToFocus = data.payload as number;
          break;
      }
    };

    window.addEventListener('message', this.messageListener);
    this.sendMsgToParent({ action: 'READY' });
  }

  ngOnDestroy()
  {
    if (this.messageListener)
    {
      window.removeEventListener('message', this.messageListener);
    }
  }

  onSearchFound(found: boolean)
  {
    if (!found)
    {
      const msg: TextCompareMessageToParent = { action: 'SEARCH_NOT_FOUND', payload: this.searchText, iframeIndex: this.iframeIndex };
      this.sendMsgToParent(msg);
    }
  }

  onScrollChange(event: ScrollPosition)
  {
    const msg: TextCompareMessageToParent = { action: 'SCROLL_CHANGE', payload: event, iframeIndex: this.iframeIndex };
    this.sendMsgToParent(msg);
  }

  onZoomLevelChange(zoom: number | string)
  {
    const msg: TextCompareMessageToParent = { action: 'ZOOM_CHANGE', payload: zoom, iframeIndex: this.iframeIndex };
    this.sendMsgToParent(msg);
  }

  onTextSelected(text: string)
  {
    const msg: TextCompareMessageToParent = { action: 'TEXT_SELECTED', payload: text, iframeIndex: this.iframeIndex };
    this.sendMsgToParent(msg);
  }

  async onAllTextReaded(allText: string)
  {
    const msg: TextCompareMessageToParent = { action: 'FULL_TEXT_READED', payload: allText, iframeIndex: this.iframeIndex };
    this.sendMsgToParent(msg);
  }

  onEqualDiffClicked(index: number)
  {
    const msg: TextCompareMessageToParent = { action: 'EQUAL_DIFF_CLICKED', payload: index, iframeIndex: this.iframeIndex };
    this.sendMsgToParent(msg);
  }

  private highlightDifferences(diffs: TextDifference[], highlightInSelection: boolean)
  {
    const diffTypeToDiscard = this.iframeIndex === 1 ? DiffType.Inserted : DiffType.Deleted;
    const filteredDiffs = diffs.filter(d => d.type !== diffTypeToDiscard);

    this.diffsToHighlight =
    {
      textDifferences: filteredDiffs,
      highlightInSelection: highlightInSelection,
      keepOldHighlights: highlightInSelection
    };
  }

  private removeDiffHighlights()
  {
    this.diffsToHighlight =
    {
      textDifferences: [],
      highlightInSelection: false,
      keepOldHighlights: false
    };
  }

  private sendMsgToParent(msg: TextCompareMessageToParent)
  {
    window.parent.postMessage(msg, '*');
  }
}
